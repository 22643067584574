import React from 'react'
import cx from 'classnames'

import { Input, Textarea, Text, Button } from '../../atoms'
import * as styles from './contact-form.module.scss'

type Props = {
  className?: string
  style?: React.CSSProperties
}

export const ContactForm: React.FC<Props> = (props) => {
  return (
    <section className={styles.contactSection} {...props}>
      <div className={cx(styles.inner, 'container')}>
        <Text as="h2" textType="subTitle">
          FORM
        </Text>
        <Text as="p" className={styles.comment}>
          ご回答可能なものにつきましては、後日こちらからご連絡差し上げます。
          <br />
          内容によっては、返答にお時間をいただく場合もございます。予めご了承ください。
        </Text>
        <form
          className={styles.contactForm}
          action="/contact-success"
          name="contact"
          method="POST"
          data-netlify="true"
        >
          <input type="hidden" name="form-name" value="contact" />
          <Text as="label" size="l" htmlFor="company">
            会社名
          </Text>
          <Input className={styles.input} id="company" type="text" name="company" />
          <Text as="label" size="l" htmlFor="fullname">
            お名前
          </Text>
          <Input className={styles.input} id="fullname" type="text" name="fullname" required />
          <Text as="label" size="l" htmlFor="email">
            メールアドレス
          </Text>
          <Input className={styles.input} id="email" type="email" name="email" required />
          <Text as="label" size="l" htmlFor="msg">
            お問い合わせ内容
          </Text>
          <Textarea className={styles.textarea} id="msg" name="msg" required />
          <Button className={styles.submitButton} type="submit" shape="round">
            send
          </Button>
        </form>
      </div>
    </section>
  )
}

export default ContactForm
