import React from 'react'
import { PageProps } from 'gatsby'

import { Layout, SEO, TopImageBox } from '../components/organisms/common'
import { ContactForm } from '../components/organisms/contact'

const Contact: React.FC<PageProps> = () => {
  return (
    <Layout path="/contact/">
      <TopImageBox filename="contact.jpeg" alt="contact top image" title="Contact" />
      <ContactForm />
    </Layout>
  )
}

export default Contact

export const Head = () => <SEO />
